import React from "react";
import professional_photo from "../images/professional_photo2.png";

export default function Aboutme() {
  return (
    <div>
      <div className="container first_section">
        <div className="row">
          <div className="col-12 second_title">Ciao!</div>

          <div className="col-12">
            {" "}
            <div className="row">
              <div className="col-sm-12 focus_title ">SONO MARTINA BOSSO</div>
              <div className="col-12 ">
                <img
                  src={professional_photo}
                  alt="martina_photo_professional"
                  className="media_style "
                />
              </div>

              <div className="col-sm-12 content">
                <p className="content mt-2">
                  Biologa nutrizionista con ufficio privato a Nola (NA);
                  iscritta all’Albo dei Biologi. <br />
                  Autrice di articoli nutrizionali per la rivista scientifica{" "}
                  <strong>Salute 33</strong>. Aderito alla partecipazione di
                  varie manifestazioni: alla giornata di prevenzione di Race for
                  the Cure a Roma e alla Giornata del biologo in piazza a
                  Napoli.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container second_section">
        <div className="row">
          <div className="col-12  slogan">
            "Mangiare bene e sano <div>è un atto d'amore verso se stessi"</div>
          </div>

          <div className="col-12">
            {" "}
            <div className="row">
              <div className="col-12">
                <p className="content">
                  Questo semplice slogan è la sintesi della mia filosofia non
                  solo lavorativa, ma di vita. Approcciarsi in modo consapevole
                  e critico nei confronti del cibo è la base per vivere in modo
                  sano. L’obiettivo di un percorso nutrizionale deve essere
                  quello di cambiare il proprio modo di vivere e abbandonare
                  alle spalle lo stile di vita errato. Non abbiate paura del
                  cambiamento, ma affrontatelo in modo convinto; il tempo ed i
                  sacrifici sapranno come ricompensare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
