import React from "react";

export default function Service() {
  return (
    <div className="container">
      <h1 className="titleServices mt-5">Servizi e Consulenze</h1>

      <main>
        <div className="row">
          <div className="firstSection">
            <h4 className="headerServices border-top mt-3">VISITA IN STUDIO</h4>
            <p className="contentServices">
              La visita ha una durata media di circa un’ora, in modo tale che
              sia possibile instaurare un rapporto empatico e di conoscere le
              abitudini alimentari.{" "}
            </p>
            <h4 className="headerServices">Come si svolge la visita?</h4>
            <p className="contentServices">
              Durante la visita verrà effettuata una valutazione delle
              condizioni generali di salute: stile di vita, allergie, regolarità
              intestinale, regolarità del ciclo, eventuali disturbi etc.
              <br /> Verranno effettuate le{" "}
              <strong>misure antropometriche</strong> (misurazione della
              circonferenza, vita, fianchi,torace) e una
              <strong> bioimpedenziometria (BIA)</strong>, che permetterà di
              osservare lo stato di idratazione, la massa grassa e massa magra
              della persona.
            </p>
            <h4 className="headerServices">Cosa occorre per la consulenza?</h4>{" "}
            <p className="contentServices">
              <ul>
                <li>Esami Ematochimici di routine</li>
                <li>Storia clinica (in caso di patologie accertate)</li>
              </ul>
            </p>
            <h4 className="headerServices">
              Cosa succede dopo la prima visita?
            </h4>
            <p className="contentServices">
              <ul>
                <li>
                  verrà consegnato via e-mail entro una settimana dalla visita
                  un piano alimentare PERSONALIZZATO;
                </li>

                <li>
                  verrà fissata la data di un appuntamento di controllo. In
                  genere avverrà dopo circa 3 settimane, anche se tale lasso
                  temporale può variare in base alle singole esigenze.
                </li>
              </ul>
            </p>{" "}
            <div className="call_to_action mb-3">
              <strong>
                Per prenotare o chiedere maggiori informazioni cliccare sul
                bottone in basso 👇
              </strong>
            </div>
            <button className="buttonServices mt-3">
              {" "}
              <a
                href="mailto:dottoressamartinabosso@gmail.com"
                className="linkServices"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Prenota una consulenza
              </a>
            </button>
          </div>

          <div className="secondSection">
            <h4 className="headerServices border-top mt-5">
              CONSULENZA ONLINE
            </h4>
            <p className="contentServices">
              {" "}
              Vista la situazione attuale del <strong>Covid-19</strong> e le
              misure adottate dal governo, è stata data la possibilità ai
              Biologi Nutrizionisti di effettuare le consulenze on- line.
              <br /> Il 5 novembre, alla luce della incredibile straordinarietà
              della situazione legata al grado di diffusione del{" "}
              <strong>Covid-19</strong>, il Presidente del Consiglio dell’Ordine
              Nazionale dei Biologi decreta di sospendere i divieti contenuti
              nel documento approvato con delibera n. 433 del 26 settembre 2019
              in tema di svolgimento on-line dell’attività professionale in
              campo nutrizionale, permettendoci quindi di effettuare le
              consulenze a distanza.
            </p>
            <h4 className="headerServices">
              Come si svolge la consulenza online?
            </h4>
            <p className="contentServices">
              La consulenza a distanza verra' effettuata tramite video chiamata.
              una volta confermata la prenotazione verrá condiviso il link per
              accedere al meeting. La seduta avra' una durata di circa un'ora.
              Durante la visita verrà effettuata una valutazione delle
              condizioni generali di salute: stile di vita, allergie, regolarità
              intestinale, regolarità del ciclo, eventuali disturbi etc. Le
              misure antropometriche verranno prese dal paziente con il mio
              aiuto.
            </p>
            <h4 className="headerServices">
              Cosa occorre per la consulenza online?
            </h4>{" "}
            <p className="contentServices">
              <ul>
                <li>Bilancia</li>
                <li>Metro</li>
                <li>Esami Ematochimici di routine</li>
                <li>Storia clinica (in caso di patologie accertate)</li>
              </ul>
            </p>
          </div>

          <div className="thirdSection">
            <h4 className="headerServices">Come si prenota?</h4>
            <p className="contentServices">
              La consulenza puo' essere prenotata attraverso e-mail tramite il
              bottone in basso. La prenotazione verra' confermata alla ricezione
              del pagamento.
            </p>
            <h4 className="headerServices">Come avviene il pagamento?</h4>
            <p className="contentServices">
              La consulenza deve essere prenotata via email e pagata in anticipo
              attraverso un bonifico o un sistema di pagamento rapido
              alternativo (PayPal, carta di credito). All’atto del pagamento
              verranno chiesti i dati necessari per l’inoltro della ricevuta
              fiscale.
            </p>
            <div className="call_to_action mb-3">
              <strong>
                Per prenotare o chiedere maggiori informazioni cliccare sul
                bottone in basso 👇
              </strong>
            </div>
            <button className="buttonServices mt-3">
              {" "}
              <a
                href="mailto:dottoressamartinabosso@gmail.com"
                className="linkServices mt-3"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Prenota una consulenza
              </a>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
