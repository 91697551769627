import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./styles_component.css";

export default function Footer() {
  return (
    <div className="container">
      <ul className="contact_links">
        <li className="contact_list">
          <a
            href="https://www.miodottore.it/martina-bosso/nutrizionista-dietologo-dietista/marigliano"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-user-nurse footer_icon"></i>
          </a>
        </li>

        <li className="contact_list">
          <a
            href="https://www.facebook.com/nutrizionistamartinabosso"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook footer_icon"></i>
          </a>
        </li>
        <li className="contact_list">
          <a
            href="https://www.youtube.com/channel/UC0li9eFZ8_NrNLwLwneYZcg/videos"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube-square footer_icon"></i>{" "}
          </a>
        </li>
        <li className="contact_list">
          <a
            href="https://www.instagram.com/dottoressamartinabosso/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram footer_icon"></i>{" "}
          </a>
        </li>
        <li className="contact_list">
          <a
            href="mailto:dottoressamartinabosso@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-envelope-open-text footer_icon"></i>{" "}
          </a>
        </li>
      </ul>
    </div>
  );
}
