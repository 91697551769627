import React from "react";
import logo from "../images/logo.png";

export default function Contact() {
  return (
    <div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h1 className="focus_title">Get in touch!</h1>
            </div>

            <div className="col-12">
              <ul className="contactPageLinks">
                <li>
                  <a
                    href="https://www.miodottore.it/martina-bosso/nutrizionista-dietologo-dietista/marigliano"
                    target="_blank"
                    rel="noreferrer"
                    className="contactPageLinks"
                  >
                    MioDottore
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/nutrizionistamartinabosso"
                    target="_blank"
                    rel="noreferrer"
                    className="contactPageLinks"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC0li9eFZ8_NrNLwLwneYZcg/videos"
                    target="_blank"
                    rel="noreferrer"
                    className="contactPageLinks"
                  >
                    YouTube
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/dottoressamartinabosso/"
                    target="_blank"
                    rel="noreferrer"
                    className="contactPageLinks"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:dottoressamartinabosso@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="contactPageLinks"
                  >
                    E-mail
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <img
            src={logo}
            alt="contactMe"
            className="medium_size_img img-fluid "
          />
        </div>
      </div>
    </div>
  );
}
