import { useParams } from "react-router-dom";
import { findSecondCourse } from "../../data";

const SecondCourseProcedure = () => {
  let params = useParams();
  const id = Number(params.secondCourseId);
  const secondCourse = findSecondCourse(id);

  return (
    <div>
      <img
        src={secondCourse.image.src}
        alt={secondCourse.image.alt}
        className="media_style"
      />
      <h4 className="focus_title">{secondCourse.name}</h4>

      <div className="col-12 content">{secondCourse.dateOfTheRecipe}</div>
      <h3 className="col-12 slogan ">La nutrizionista consiglia</h3>
      <p
        className="col-12 content"
        dangerouslySetInnerHTML={{ __html: secondCourse.description }}
      />
      <h3 className="col-12 slogan">Ingredienti</h3>
      <p className="col-12 content">{secondCourse.ingredients}</p>

      <h3 className="col-12 slogan">Procedimento</h3>
      <p className="col-12 content">{secondCourse.steps}</p>
    </div>
  );
};

export default SecondCourseProcedure;
