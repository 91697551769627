import { useParams } from "react-router-dom";
import { findDessert } from "../../data";

const DessertProcedure = () => {
  let params = useParams();

  const id = Number(params.dessertId);
  const dessert = findDessert(id);

  return (
    <div>
      <img
        src={dessert.image.src}
        alt={dessert.image.alt}
        className="media_style"
      />
      <h4 className="focus_title">{dessert.name}</h4>

      <div className="col-12 content">{dessert.dateOfTheRecipe}</div>
      <h3 className="col-12 slogan">La nutrizionista consiglia</h3>
      <p className="col-12 content">{dessert.description}</p>
      <h3 className="col-12 slogan">Ingredienti</h3>
      <p className="col-12 content">{dessert.ingredients}</p>
      <h3 className="col-12 slogan">Procedimento</h3>
      <p
        className="col-12 content"
        dangerouslySetInnerHTML={{ __html: dessert.steps }}
      />
    </div>
  );
};

export default DessertProcedure;
