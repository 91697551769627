import React from "react";
import profile_picture from "../images/martina_bosso_smaller_size.png";

export default function Homepage() {
  return (
    <div>
      <div className="container">
        <div>
          <div className="row">
            <div className="col-12">
              <h1 className="second_title mt-3">Martina Bosso</h1>
              <h2 className="focus_title">BIOLOGA NUTRIZIONISTA</h2>
            </div>

            <div className="col-12">
              <img
                src={profile_picture}
                alt="martina_photo"
                className="media_style "
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <p className="content mt-3">
                <div className="slogan"> Consulenze personalizzate</div>{" "}
                Effettuo consulenze individuali, familiari, di coppia, workshop
                aziendali e laboratori scolastici.
              </p>

              <p className="content mt-3">
                <div className="slogan">Piani alimentari</div> Offro piani
                alimentari in linea con l’obiettivo del paziente, della
                condizione patologica, delle preferenze e/o abitudini di vita
                ma, cosa più importante, della personalità emotiva e
                caratteriale di chi ho di fronte.
              </p>
            </div>
          </div>
          <p className="call_to_action mb-3 mt-3">
            <strong>
              Per prenotare o chiedere maggiori informazioni cliccare sul
              bottone in basso 👇{" "}
            </strong>
          </p>
          <button className="buttonServices">
            {" "}
            <a
              href="mailto:dottoressamartinabosso@gmail.com"
              className="linkServices"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Prenota una consulenza
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
