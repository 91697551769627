import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./styles_component.css";

export default function NavMenu() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light nav_bar">
      <div className="container-fluid">
        <NavLink
          style={({ isActive }) => {
            return {
              color: isActive ? "white" : "",
            };
          }}
          to="/"
          className="navbar nav_title "
        >
          Dott.ssa Martina Bosso
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                style={({ isActive }) => {
                  return {
                    color: isActive ? "white" : "",
                  };
                }}
                to="/"
                className="nav-link nav_menu "
              >
                Homepage{" "}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                style={({ isActive }) => {
                  return {
                    color: isActive ? "white" : "",
                  };
                }}
                to="/Servizi"
                className="nav-link nav_menu "
              >
                Servizi & consulenze
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                style={({ isActive }) => {
                  return {
                    color: isActive ? "white" : "",
                  };
                }}
                to="/About"
                className="nav-link nav_menu "
              >
                About{" "}
              </NavLink>
            </li>

            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle nav-link nav_menu"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Sana alimentazione
              </span>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <NavLink
                    to="/Sanaalimentazioneesalute/Primi"
                    className="dropdown-item nav-link nav_menu"
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "white" : "",
                        backgroundColor: isActive ? "grey" : "white",
                      };
                    }}
                  >
                    Primi
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Sanaalimentazioneesalute/Secondiecontorni"
                    className="dropdown-item nav-link nav_menu"
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "white" : "",
                        backgroundColor: isActive ? "grey" : "white",
                      };
                    }}
                  >
                    Secondi e contorni
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Sanaalimentazioneesalute/Dolci"
                    className="dropdown-item nav-link nav_menu"
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "white" : "",
                        backgroundColor: isActive ? "grey" : "white",
                      };
                    }}
                  >
                    Dolci
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <NavLink
                style={({ isActive }) => {
                  return {
                    color: isActive ? "white" : "",
                  };
                }}
                to="/Contatti"
                className="nav-link nav_menu "
              >
                Contatti
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
