import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import MainCourse from "./pages/Subpages/MainCourse";
import SecondCourse from "./pages/Subpages/SecondCourse";
import Dessert from "./pages/Subpages/Dessert";
import Aboutme from "./pages/Aboutme";
import Contact from "./pages/Contact";
import NavMenu from "./component/NavMenu";
import Footer from "./component/Footer";
import Service from "./pages/Service";
import "bootstrap/dist/css/bootstrap.css";
import "./pages/styles.css";
import DessertProcedure from "./pages/Subpages/DessertProcedure";
import MainCourseProcedure from "./pages/Subpages/MainCourseProcedure";
import SecondCourseProcedure from "./pages/Subpages/SecondCourseProcedure";
export default function App() {
  return (
    <div className="App">
      <div className="page_container">
        <div>
          <NavMenu />
        </div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/About" element={<Aboutme />} />

          <Route>
            <Route className="mainCourse_subpage">
              <Route
                path="/Sanaalimentazioneesalute/Primi"
                element={<MainCourse />}
              />

              <Route
                path="/Sanaalimentazioneesalute/Primi/:mainCourseId"
                element={<MainCourseProcedure />}
              />
            </Route>

            <Route className="secondeCourse_subpage">
              <Route
                path="/Sanaalimentazioneesalute/Secondiecontorni"
                element={<SecondCourse />}
              />

              <Route
                path="/Sanaalimentazioneesalute/Secondiecontorni/:secondCourseId"
                element={<SecondCourseProcedure />}
              />
            </Route>

            <Route className="dessert_subpage">
              <Route
                path="/Sanaalimentazioneesalute/Dolci"
                element={<Dessert />}
              />

              <Route
                path="/Sanaalimentazioneesalute/Dolci/:dessertId"
                element={<DessertProcedure />}
              />
            </Route>
          </Route>
          <Route path="/Servizi" element={<Service />} />
          <Route path="/Contatti" element={<Contact />} />
        </Routes>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
